<template>
  <div id="smartcam" class="container">
    <PageLoader v-if="loading"/>
    <div class="warning" v-if="!verifyShowData && !loading">
      <br /><br />
      <div class="text">
        <h1>Idade ou quantidade de dados insuficiente</h1>
      </div>
    </div>
    <div v-if="verifyShowData  && smartCamDataGrafica.length > 0 && !loading">
      <div class="detalhar-dados">
        <div
          title="Dias decorridos entre o início e o dia da finalização do lote ou o dia atual."
        >
          <p>Andamento</p>
          <span>{{dateCompare(batch.date_accomodation, last_batch_weight.datetime_fetched)}} dias</span>
        </div>

        <div  id="losses" title="Quantidade de animais vinculados a este lote" v-on:click="showGraph('losses')" :class="lossesChart ? 'borderActive' : ''">
          <p>Animais</p>
          <span class="valor-estimado" style="color: #6B6C6C;" title="Quantidade total de animais">{{batch.initial_count}}</span> •
          <span  title="Quantidade de animais vivos">{{batch.initial_count - lossesTotal}}</span>
        </div>

        <div :style="cardStyleBackground" id="peso" title="Peso registrado hoje (kg)" v-on:click="showGraph('weight')" :class="weigthChart ? 'borderActive' : ''">
          <p>Peso em kg</p>
          <span id="weight_estimado" class="valor-estimado">{{cardExpectedWeight.weight.toFixed(2)}}</span> •
          <span id="weight">{{last_batch_weight.weight.toFixed(2)}}</span>
        </div>

        <div id="desvioPadrao" title="Desvio padrão (kg)" v-on:click="showGraph('standardDeviation')" :class="standandDeviationChart ? 'borderActive' : ''">
          <p>DesvPad</p>
          <span>{{last_batch_weight.standard_deviation.toFixed(2)}} kg</span>
        </div>

        <div
          id="uniformidade"
          title="Percentual de animais que variam em +-10% o peso médio do lote."
          v-on:click="showGraph('uniformity')"
          :class="uniformityChart ? 'borderActive' : ''"   
        >
          <p>Uniformidade</p>
          <span id="uniformity">{{(100*last_batch_weight.uniformity).toFixed(2)}} %</span>
        </div>

        <div id="crescimento" title="Evolução média nos últimos sete dias (kg)." v-on:click="showGraph('gpdWeek')"  :class="gpdWeekChart ? 'borderActive' : ''">
          <p>GPD Semana</p>
          <span class="valor-estimado">{{(cardGPDWeekExpected(cardGPDWeek.x).y).toFixed(2)}}</span> •
          <span class="valor-atual-ruim" :style="cardStyleColor(cardGPDWeekExpected(cardGPDWeek.x).y, cardGPDWeek.y)">{{(cardGPDWeek.y).toFixed(2)}}</span
          >
        </div>

        <div id="crescimento" title="Evolução média de todos os dias (kg)." v-on:click="showGraph('gpdAcc')" :class="gpdAccChart ? 'borderActive' : ''">
          <p>GPD Acumulado</p>
          <span class="valor-estimado">{{cardGPDAccExpected(cardGPDAcc.x).y}}</span> •
          <span class="valor-atual-ruim" :style="cardStyleColor(cardGPDAccExpected(cardGPDAcc.x).y, cardGPDAcc.y)">{{(cardGPDAcc.y).toFixed(2)}}</span
          >
        </div>
      </div>

      <div class="clear">
        <p class="alert-success">
          Desempenho do lote dentro da média estimada. Peso alvo de abate atingido
          em: 03/08/2020.
        </p>
      </div>

      <div>
        <SmartCamChart :key="componentKey" v-if="lossesChart" v-bind:camDates="camDates" v-bind:camManualDates="camManualDates" v-bind:min_x="0" v-bind:max_x="max_x" v-bind:sets="lossesChartSetting" v-bind:label="'Animais [Unid]'" v-bind:max_y="batch.initial_count" v-bind:min_y="(batch.initial_count - lossesTotal * 2)"/>
        <SmartCamChart :key="componentKey" v-if="weigthChart" v-bind:camDates="camDates" v-bind:camManualDates="camManualDates" v-bind:min_x="min_x" v-bind:max_x="max_x" v-bind:sets="weightChartSetting" v-bind:label="'Peso [kg]'" v-bind:max_y="maxWeightHeight" v-bind:min_y="minWeightHeight"/>
        <SmartCamChart :key="componentKey" v-if="standandDeviationChart" v-bind:camDates="camDates" v-bind:min_x="min_x" v-bind:max_x="max_x" v-bind:sets="standandDeviationChartSetting" v-bind:label="'Desvio Padrão [kg]'" v-bind:min_y="0"/>
        <SmartCamChart :key="componentKey" v-if="uniformityChart" v-bind:camDates="camDates" v-bind:min_x="min_x" v-bind:max_x="max_x" v-bind:sets="uniformityChartSetting" v-bind:label="'Uniformidade [%]'" v-bind:max_y="100" v-bind:min_y="0"/>
        <SmartCamChart :key="componentKey" v-if="gpdWeekChart" v-bind:camDates="camDates" v-bind:min_x="min_x" v-bind:max_x="max_x" v-bind:sets="gpdWeekChartSetting" v-bind:label="'GPD Semana [kg]'" />
        <SmartCamChart :key="componentKey" v-if="gpdAccChart" v-bind:camDates="camDates" v-bind:min_x="min_x" v-bind:max_x="max_x" v-bind:sets="gpdAccChartSetting" v-bind:label="'GPD Acumulado [kg]'"/>
      </div>

    </div>
  </div>
</template>

<script>
import SmartCamChart from "../components/SmartCamChart";
import PageLoader from "../components/Loader.vue"

export default {
  name: "SmartCam",
  components: {
    SmartCamChart,
    PageLoader,
  },
  data() {
    return {
      componentKey: 0,
      dataFetch : [],
      loading: true
    }
  },
  props:['curveWeightReference', 'userLevel'],
  methods: {
    gpdWeek(accomodation) {
      return this.smartCamDataGrafica.map((item, index) => {
        if (index === 0) {
          return {
            "x": this.dateCompare(item.datetime_fetched, accomodation),
            "y": 1
          }
        }
        if (index < 7) {
          return {
            "x": this.dateCompare(item.datetime_fetched, accomodation),
            "y": Number(((item.weight - this.smartCamDataGrafica[0].weight)/index).toFixed(4))
          }
        }
        else {
          return {
            "x": this.dateCompare(item.datetime_fetched, accomodation),
            "y": Number(((item.weight - this.smartCamDataGrafica[index - 6].weight)/6).toFixed(4))
          }
        }
      })
    },
    gpdWeekExpected() {
      return this.curveWeightReference.map((item, index) => {
        if (index === 0) {
          return {
            "x": item.age,
            "y": 1
          }
        }
        if (index < 7) {
          return {
            "x": item.age,
            "y": Number(((item.weight - this.curveWeightReference[0].weight)/index).toFixed(4))
          }
        }
        else {
          return {
            "x": item.age,
            "y": Number(((item.weight - this.curveWeightReference[index - 6].weight)/6).toFixed(4))
          }
        }
      })
    },
    cardGPDWeekExpected(final_age) {
      const gpd_list = this.gpdWeekExpected()
      const gpd_expected = gpd_list.find(item => {
        if (item !== null) {
          return item.x === final_age
        }
      })
      if (gpd_expected === undefined) {
        return {"x": 0, "y": 0}
      }
      else {
        return gpd_expected
      }
    },
    gpdAcc(accomodation) {
      return this.smartCamDataGrafica.map(item => {
        if ((this.dateCompare(item.datetime_fetched, accomodation)) < 29) {
          return null
        }
        else {
          return {
            "x": this.dateCompare(item.datetime_fetched, accomodation),
            "y": Number(((item.weight - this.batch.average_initial_weight)/(this.dateCompare(item.datetime_fetched, accomodation) - 1)).toFixed(4))

          }
        }
      })
    },
    gpdAccExpected() {
      return this.curveWeightReference.map(item => {
        if (item.age < 29) {
          return null
        }
        else {
          return {
            "x": item.age,
            "y": Number(((item.weight - this.batch.average_initial_weight)/(item.age - 1)).toFixed(4))
          }
        }
      })
    },
    cardGPDAccExpected(final_age) {
      const gpd_list = this.gpdAccExpected()
      const gpd_expected = gpd_list.find(item => {
        if (item !== null) {
          return item.x === final_age
        }
      })
      if (gpd_expected === undefined) {
        return {"x": 0, "y": 0}
      }
      else {
        return gpd_expected
      }
    },
    forceRerender: function() {
      this.componentKey += 1
    },
    showGraph: function(chart) {
      if (chart === 'uniformity' || chart === 'standardDeviation') {
        if (this.userRole === 'farm_employee') return;
      }
      this.$store.dispatch("chartDisplay", chart)
      this.forceRerender()
    },
    dateCompare: function(first_date, second_date) {
      return Math.ceil(Math.abs(new Date(second_date) - new Date(first_date)) / (1000 * 60 * 60 * 24));
    },
    camWeights: function(accomodation) {
      return this.batch_weight.map(item => {
        return {
          "x": this.dateCompare(item.datetime_fetched, accomodation),
          "y": item.weight
        }
      })
    },
    camWeightsV3: function(accomodation) {
      return this.camV3.map(item => {
        return {
          "x": this.dateCompare(item.datetime_fetched, accomodation),
          "y": item.weight
        }
      })
    },
    manualWeights: function(accomodation) {
      try {
        return this.batch.manual_weight.map(item => {
          return {
            "x": this.dateCompare(item.datetime_fetched, accomodation),
            "y": item.weight
          }
        })
      }
      catch(err) {
        return null
      }
    },
    standardDeviation: function(accomodation) {
      return this.batch_weight.map(item => {
        return {
          "x": this.dateCompare(item.datetime_fetched, accomodation),
          "y": item.standard_deviation
        }
      })
    },
    uniformity: function(accomodation) {
      return this.batch_weight.map(item => {
        return {
          "x": this.dateCompare(item.datetime_fetched, accomodation),
          "y": Number((100*item.uniformity).toFixed(4))
        }
      })
    },
    cardStyleColor: function(expected, real) {
      const percentage = (real * 100)/expected
      if (percentage > 105) {
        return 'color: #415430;'
      }
      if (percentage >= 100) {
        return 'color: #a1cd7b'
      }
      if (percentage >= 95) {
        return 'color: #e2bf65'
      }
      if (percentage < 95) {
        return 'color: #a94949'
      }
    },
    dataLosses: function(accomodation){
      // When the user changes the farm, we need to force a re-render so it doesn't see the data from the previous farm
      this.forceRerender()

      const lossesByBatch = this.$store.getters.getLossesBatch
      const initialNumberOfPigs = this.batch.initial_count
      const durationBatchInDays = this.dateCompare(accomodation, this.last_batch_weight.datetime_fetched)
      const pigsAliveEachDay = []

      for(let i = 0; i < durationBatchInDays + 1; i++){
        let totalDeathsTillThisDay = 0
        lossesByBatch.forEach(item => {
          if (this.dateCompare(item.death_datetime, accomodation) <= i){
            totalDeathsTillThisDay += item.quantity
          }
        })
        
        pigsAliveEachDay.push({
          x: i,
          y: initialNumberOfPigs - totalDeathsTillThisDay,
        })
      }
      return pigsAliveEachDay
    },
  },
  computed: {
    camV3() {
      const data = typeof this.$store.getters.getCamv3Data == 'string' ? [] : this.$store.getters.getCamv3Data  
      return data.map((obj)=>{
        return {
          ...obj,
          datetime_fetched: obj.date,
          weight: obj.weight_mean,
          standard_deviation: obj.weight_std
        }
      })
    },
    age() {
      let date = new Date(this.batch.date_accomodation);
      date.setDate(date.getDate() + 30)
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      date = day + "/" + month + "/" + year;
      return `${date}`
    },
    camDates: function() {
      return this.batch_weight.map(item => item.datetime_fetched)
    },
    camManualDates: function() {
      if (this.batch.manual_weight == undefined) {
        return []
      }
      return this.batch.manual_weight.map(item => item.datetime_fetched)
    },
    cardGPDAcc() {
      const gpd_list = this.gpdAcc(this.batch.date_accomodation)
      const gpd_acc = gpd_list[gpd_list.length - 1]
      if (gpd_acc === null) {
        return {"x": 0, "y": 0}
      }
      else {
        return gpd_acc
      }
    },
    cardGPDWeek() {
      const gpd_list = this.gpdWeek(this.batch.date_accomodation)
      return gpd_list[gpd_list.length - 1]
    },
    cardExpectedWeight() {
      const expected_weight = this.curveWeightReference.find(item => item.age === this.dateCompare(this.last_batch_weight.datetime_fetched, this.batch.date_accomodation) - 1)
      if (expected_weight === undefined) {
        return {"age": 0, "weight": 0}
      }
      else {
        return expected_weight
      }
    },
    min_x() {
      if ((this.dateCompare(this.batch_weight[0].datetime_fetched, this.batch.date_accomodation) - 1) >= 29) {
        return this.dateCompare(this.batch_weight[0].datetime_fetched, this.batch.date_accomodation) - 1
      }
      else {
        return 29
      }
    },
    max_x() {
      return this.dateCompare(this.batch.date_departure == null ? this.selectDataCam.datetime_fetched : this.batch.date_departure, this.batch.date_accomodation)
    },
    expectedWeight() {
      return this.curveWeightReference.map(item => {
        return {
          "x": item.age,
          "y": item.weight
        }
      })
    },
    lossesChart(){
      return this.$store.getters.getLossesChartStatus
    },
    lossesChartSetting () {
      const settings = [
        {
          label: 'Losses',
          pointRadius: 3,
          data: this.dataLosses(this.batch.date_accomodation),
          type: 'line',
          backgroundColor: "rgba(137, 194, 91, 0.5)",
          pointBackgroundColor: '#89c25b',
          borderColor: '#89c25b',
          borderWidth: 2,
          lineTension: 0.6,
        }
      ]
      return settings
    },
    weightChartSetting() {
      const settingWithCamv3 = [
          {
            label: "SmartCam",
            backgroundColor: "#89c25b",
            data: this.camWeights(this.batch.date_accomodation),
            pointRadius: 3,
            type: 'line',
            pointBackgroundColor: '#89c25b',
            borderColor: '#89c25b',
            borderWidth: 2,
            lineTension: 0.6,
            fill: false
          },
          {
            label: "Manual",
            backgroundColor: "#87165c",
            data: this.manualWeights(this.batch.date_accomodation),
            pointRadius: 6,
            type: 'line',
            pointBackgroundColor: '#87165c',
            borderColor: '#87165c',
            borderWidth: 2,
            lineTension: 0.6,
            fill: false
          },
          {
            label: "Esperado",
            backgroundColor: "#171a5c",
            data: this.expectedWeight,
            pointRadius: 2,
            type: 'line',
            pointBackgroundColor: '#171a5c',
            borderColor: '#171a5c',
            borderWidth: 2,
            lineTension: 0.6,
            fill: false
          },
          {
            label: "SmartCamV3",
            backgroundColor: "red",
            data: this.camWeightsV3(this.batch.date_accomodation),
            pointRadius: 2,
            type: 'line',
            pointBackgroundColor: 'red',
            borderColor: 'red',
            borderWidth: 2,
            lineTension: 0.6,
            fill: false
          }
      ]
      
      const setting = [
          {
            label: "SmartCam",
            backgroundColor: "#89c25b",
            data: this.camWeights(this.batch.date_accomodation),
            pointRadius: 3,
            type: 'line',
            pointBackgroundColor: '#89c25b',
            borderColor: '#89c25b',
            borderWidth: 2,
            lineTension: 0.6,
            fill: false
          },
          {
            label: "Manual",
            backgroundColor: "#87165c",
            data: this.manualWeights(this.batch.date_accomodation),
            pointRadius: 6,
            type: 'line',
            pointBackgroundColor: '#87165c',
            borderColor: '#87165c',
            borderWidth: 2,
            lineTension: 0.6,
            fill: false
          },
          {
            label: "Esperado",
            backgroundColor: "#171a5c",
            data: this.expectedWeight,
            pointRadius: 2,
            type: 'line',
            pointBackgroundColor: '#171a5c',
            borderColor: '#171a5c',
            borderWidth: 2,
            lineTension: 0.6,
            fill: false
          },
        ]
      return this.userLevel == 'pecsmart' ? settingWithCamv3 : setting
    },
    standandDeviationChartSetting() {
      const setting = [
        {
          label: 'Desvio Padrão',
          pointRadius: 3,
          data: this.standardDeviation(this.batch.date_accomodation),
          type: 'line',
          backgroundColor: "rgba(137, 194, 91, 0.5)",
          pointBackgroundColor: '#89c25b',
          borderColor: '#89c25b',
          borderWidth: 2,
          lineTension: 0.6,
        }
      ]
      return setting
    },
    uniformityChartSetting() {
      const setting = [
        {
          label: 'Uniformidade',
          pointRadius: 3,
          data: this.uniformity(this.batch.date_accomodation),
          type: 'line',
          backgroundColor: "rgba(137, 194, 91, 0.5)",
          borderColor: '#89c25b',
          pointBackgroundColor: '#89c25b',
          borderWidth: 2,
          lineTension: 0.6,
        }
      ]
      return setting
    },
    gpdWeekChartSetting() {
      const setting = [
        {
          label: 'GPD Esperado',
          backgroundColor: "#171a5c",
          data: this.gpdWeekExpected(),
          pointRadius: 1,
          type: 'line',
          pointBackgroundColor: '#171a5c',
          borderColor: '#171a5c',
          borderWidth: 2,
          lineTension: 0.6,
          fill: false
        },
        {
          label: 'GPD Semana',
          pointRadius: 3,
          data: this.gpdWeek(this.batch.date_accomodation),
          type: 'line',
          backgroundColor: "rgba(137, 194, 91, 0.5)",
          borderColor: '#89c25b',
          pointBackgroundColor: '#89c25b',
          borderWidth: 2,
          lineTension: 0.6,
        },
      ]
      return setting
    },
    gpdAccChartSetting() {
      const setting = [
        {
          label: 'GPD Esperado',
          backgroundColor: "#171a5c",
          data: this.gpdAccExpected(),
          pointRadius: 1,
          type: 'line',
          pointBackgroundColor: '#171a5c',
          borderColor: '#171a5c',
          borderWidth: 2,
          lineTension: 0.6,
          fill: false
        },
        {
          label: 'GPD Acumulado',
          pointRadius: 3,
          data: this.gpdAcc(this.batch.date_accomodation),
          type: 'line',
          backgroundColor: "rgba(137, 194, 91, 0.5)",
          borderColor: '#89c25b',
          pointBackgroundColor: '#89c25b',
          borderWidth: 2,
          lineTension: 0.6,
        }
      ]
      return setting
    },
    weigthChart() {
      return this.$store.getters.getWeightChartStatus
    },
    standandDeviationChart() {
      return this.$store.getters.getStandardDeviationChartStatus
    },
    uniformityChart() {
      return this.$store.getters.getUniformityChartStatus
    },
    gpdWeekChart() {
      return this.$store.getters.getGpdWeekChartStatus
    },
    gpdAccChart() {
      return this.$store.getters.getGpdAccChartStatus
    },
    maxDeviation() {
      return this.batch_weight.reduce((total, next) => Math.max(total, next.standard_deviation), 0)
    },
    minDeviation() {
      return this.batch_weight.reduce((total, next) => Math.min(total, next.standard_deviation), 0)
    },
    greaterDeviation() {
      return Math.max(Math.abs(this.maxDeviation), Math.abs(this.minDeviation))
    },
    maxWeightHeight() {
      return this.batch_weight.reduce((total, next) => Math.max(total, next.weight), 0) + 30
    },
    minWeightHeight() {
      const mapped_list = this.batch_weight.map(item => (item.weight))
      return mapped_list.reduce((total, next) => Math.min(total, next)) - 5
    },
    barn() {
      return this.$store.getters.getBarnTarget
    },
    batch() {
      return this.$store.getters.getBatchTarget
    },
    selectSensorCam() {
      return this.$store.getters.getSensorsListCam.find(obj => obj.batch.pk == this.batch.pk)
    },
    selectDataCam() {
      return this.selectSensorCam.data[0]
    },
    smartCamDataGrafica() {
      return this.$store.getters.getSmartCamData
    },
    verifyShowData() {
      return this.last_batch_weight?.datetime_fetched ? this.dateCompare(this.batch.date_accomodation, this.last_batch_weight.datetime_fetched) > 29 : false
    },
    batch_weight() {
      return this.smartCamDataGrafica
    },
    losses() {
      return this.$store.getters.getLossesBatch
    },
    lossesTotal() {
      return this.losses.reduce((sum, obj)=> sum + obj.quantity, 0)
    },
    last_batch_weight() {
      return this.smartCamDataGrafica[(this.smartCamDataGrafica.length) - 1]
    },
    userRole() {
      return this.$store.getters.getCurrentUser.role
    },
    cardStyleBackground() {
      const percentage = (this.last_batch_weight.weight * 100)/this.cardExpectedWeight.weight
      if (percentage > 105) {
        return 'background: #415430;'
      }
      if (percentage >= 100) {
        return 'background: #a1cd7b;'
      }
      if (percentage >= 95) {
        return 'background: #e2bf65;'
      }
      return 'background: #a94949;'
    },
  },
  async created() {
      await this.$store.dispatch('fetchCamData', this.batch.pk)
      if (this.losses.length === 0) await this.$store.dispatch('fecthLossesByBatch', {batch: this.batch.pk, url: null})
      if (this.userLevel == 'pecsmart') {
        try {
          await this.$store.dispatch('fetchCamV3', this.batch.pk)
        } catch (error) {
          console.log(error)
          this.loading = false
        }
      }
      
      this.loading = false
  }
};
</script>

<style scoped>
.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.warning .text {
  background: #78b843;
  border-radius: 10px;
  color: #fff;
  padding: 20px;
  font-size: 30px;
}

.warning .text h1 {
  display: flex;
  justify-content: center;
}

.warning .text p {
  display: flex;
  justify-content: center;
  margin: 0;
}

.register-batch {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.register-batch button {
  width: 400px;
  height: 200px;
  background: #fff;
  border: 4px solid #78b843;
  border-radius: 10px;
  outline: none;
  transition: 0.2s ease-out
}

.register-batch button h1 {
  font-size: 60px;
  color: #78b843
}

.register-batch button:hover {
  background: #78b843;
}

.register-batch button:hover h1,
.register-batch button:hover p{
  color: #fff;
}

.register-batch button p {
  font-size: 30px;
  color: #78b843;
  padding: 10px;
  margin: 0;
}

#peso p {
  color: white;
}

#peso #weight {
  color: white;
}

.borderActive{
  border: 3px solid #78b843;
}

#losses:hover{
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .warning {
    height: 250px;
  }

  .warning h1 {
    font-size: 30px;
  }

  .warning p {
    font-size: 20px;
  }

  .register-batch {
    height: 220px;
  }

  .register-batch button {
    width: 250px;
    height: 150px;
    font-size: 25px;
    outline: none;
    transition: 0.2s ease-out
  }

  .register-batch button h1 {
    font-size: 32px;
  }

  .register-batch button p {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .warning h1 {
    font-size: 25px;
  }

  .warning p {
    font-size: 15px;
  }
}

@media screen and (max-width: 360px) {
  .warning {
    height: 200px;
  }

  .warning h1 {
    font-size: 20px;
  }

  .warning p {
    font-size: 12px;
  }
}
</style>
