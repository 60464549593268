<template>
  <div>
    <div>
      <PageLoader v-if="loading"/>
      <SmartCam v-else :curveWeightReference="curveWeightReference" :userLevel="userLevel"/>
    </div>
    <ModalStyle>
      <RegisterBatchModal />
    </ModalStyle>
  </div>
</template>

<script>
import SmartCam from './SmartCamV2.vue'
import PageLoader from '../components/Loader.vue'
import ModalStyle from "../components/ModalStyle.vue";
import RegisterBatchModal from "../components/RegisterBatchModalOld.vue";

export default{
  name: 'SmartCamPage',
  components:{
      SmartCam,
      PageLoader,
      ModalStyle,
      RegisterBatchModal,
  },
  data() {
    return{
      loading: true
    }
  },
  computed:{
    curveWeightReference() {
      return this.$store?.getters?.getCurveWeightReference ?? []
    },
    batch() {
      return this.$store.getters.getBatchTarget
    },
    userLevel() {
      return this.$store.getters.getUserLevel
    },
  },
  async created() {
    await await this.$store.dispatch('fetchReferenceWeightCurve', this.batch.animal_type)
    this.loading = false
  }

}

</script>

<style scoped>
.register-batch {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.register-batch button {
  width: 400px;
  height: 200px;
  background: #fff;
  border: 4px solid #78b843;
  border-radius: 10px;
  outline: none;
  transition: 0.2s ease-out
}

.register-batch button h1 {
  font-size: 60px;
  color: #78b843
}

.register-batch button:hover {
  background: #78b843;
}

.register-batch button:hover h1,
.register-batch button:hover p{
  color: #fff;
}

.register-batch button p {
  font-size: 30px;
  color: #78b843;
  padding: 10px;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .warning {
    height: 250px;
  }

  .warning h1 {
    font-size: 30px;
  }

  .warning p {
    font-size: 20px;
  }

  .register-batch {
    height: 220px;
  }

  .register-batch button {
    width: 250px;
    height: 150px;
    font-size: 25px;
    outline: none;
    transition: 0.2s ease-out
  }

  .register-batch button h1 {
    font-size: 32px;
  }

  .register-batch button p {
    font-size: 20px;
  }
}
</style>
