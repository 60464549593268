import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index';

Vue.use(Vuex)

export const URL = process.env.VUE_APP_URL != undefined ? process.env.VUE_APP_URL : "https://api.pecsmart.cloud/"
export const URL_CAMV3 = "https://camv3api.pecsmart.cloud/"

export default new Vuex.Store({
  state: {
    Farms: [],
    Barns: null,
    Batchs: [],
    companies: [],
    sensorsListFeed: [],
    sensorsListMic: [],
    sensorsListCam: [],
    batchDetail: [],
    stateBarns: [],
    curveWeightReference: [],
    activeRequests : 0,
    historyBatch : [],
    selectedFarms: [],
    smartCamData:[],
    allBarns:[],
    companySettings: [],
    lossesBatch:[],
    lossesChartStatus: false,
    currentUser: null,
    logedIn: false,
    error: false,
    weightChartStatus: true,
    standardDeviationChartStatus: false,
    uniformityChartStatus: false,
    gpdWeekChartStatus: false,
    gpdAccChartStatus: false,
    header: null,
    smartMicData: [],
    smartFeedData: [],
    navbar: false,
    barn_target: null,
    batch_target: null,
    allBatches: [],
    isAdmin: null,
    userLevel: null,
    smartFeedConsolidatedData: [],
    animalTypes: null,
    errorMessage: null,
    dataCamv3: [],
  },
  mutations: {
    setDefaultValues(state) {
      state.Farms = null
      state.currentUser = null
      state.selectedFarms =  null
      state.logedIn =  false
      state.error = false
      state.header = null
      state.smartMicData = []
      state.smartFeedData = []
      state.navbar = false
      state.barn_target = null
      state.batch_target =  null
      state.allBatches = []
      state.isAdmin = null
      state.userLevel = null
      state.smartFeedConsolidatedData = []
      state.animalTypes = null
      state.sensorsListFeed = []
      state.sensorsListMic = []
      state.sensorsListCam = []
      state.curveWeightReference = []
      state.smartCamData = []
      state.companySettings = []
    },
    cleanData(state) {
      state.curveWeightReference = []
      state.smartCamData = []
      state.activeRequests = 0
      state.allBarns = []
      state.historyBatch = []
      state.batch_target = null
      state.barn_target = null
      state.batchDetail = []
      state.lossesBatch = []
      state.dataCamv3 = []
    },
    cleanTarget(state) {
      state.batch_target = null
      state.barn_target = null
      state.batchDetail = []
    },
    cleanFazendas(state){
      state.sensorsListFeed = []
      state.sensorsListMic = []
      state.sensorsListCam = []
      state.historyBatch = []
      state.companySettings = []
    },
    setCamData(state, results) {
      state.smartCamData = results
    },
    setSensorsListFeed(state, results) {
      state.sensorsListFeed.push(results)
    },
    setSensorsListMic(state, results) {
      state.sensorsListMic.push(results)
    },
    setSensorsListCam(state, results) {
      state.sensorsListCam.push(results)
    },
    setBatchDetail(state, result) {
      state.batchDetail = result
    },
    setCurveWeightReference(state, result) {
      state.curveWeightReference = result 
    },
    incrementActiveRequests(state) {
      state.activeRequests++;
    },
    decrementActiveRequests(state) {
      state.activeRequests--;
    },
    setSensorFeedTarget(state, results) {
      state.sensorFeedTarget.push = results
    },
    setFarms(state, Farm) {
      state.Farms = Farm
    },
    setAllBarns(state, barn) {
      state.allBarns.push(barn)
    },
    setHistoryBatch(state, batch) {
      batch.forEach(element => {
        state.historyBatch.push(element)
      });
    },
    updateBatchTarget(state, newBatch) {
      state.batch_target = newBatch
    },
    setCompanySettings(state, settings){
      state.companySettings = settings
    },
    setCompanies(state, companies) {
      state.companies = companies;
    },
    setLossesBatch(state, data) {
      state.lossesBatch.push(...data.results)
    },
    updateStateByBarn(state, value) {
      state.stateBarns.forEach((obj)=>{
        if (obj.pk == value.pk && value.sensor == 'smartmic') {
          obj.finishLoadMic = true
        }
        if (obj.pk == value.pk && value.sensor == 'smartcam') {
          obj.finishLoadCam = true
        }
        if (obj.pk == value.pk && value.sensor == 'smartfeed') {
          obj.finishLoadFeed = true
        }
      })
    },
    setStateBarn(state, barn){
      state.stateBarns.push(barn)
    },
    setCurrentUser(state, user) {
      state.currentUser = user
    },
    setCurrentEmployee(state, employee) {
      state.currentEmployee = employee
    },
    setFarm(state, Farm) {
      state.Farms = Farm
    },
    setSelectedFarms(state, value) {
      state.selectedFarms = value
    },
    setLogStatus(state, value) {
      state.logedIn = value
    },
    setError(state, status) {
      state.error = status
    },
    setNavbar(state, value) {
      state.navbar = value
    },
    clearChart(state) {
      state.weightChartStatus = false,
      state.standardDeviationChartStatus = false,
      state.uniformityChartStatus = false
      state.gpdWeekChartStatus = false,
      state.gpdAccChartStatus = false
      state.lossesChartStatus = false;
    },
    chartDisplay(state, chart) {
      if (chart === 'weight') {
        this.commit("clearChart")
        state.weightChartStatus = true
      }
      if (chart === 'standardDeviation') {
        this.commit("clearChart")
        state.standardDeviationChartStatus = true
      }
      if (chart === 'uniformity') {
        this.commit("clearChart")
        state.uniformityChartStatus = true
      }
      if (chart === 'gpdWeek') {
        this.commit("clearChart")
        state.gpdWeekChartStatus = true
      }
      if (chart === 'gpdAcc') {
        this.commit("clearChart")
        state.gpdAccChartStatus = true
      }
      if (chart === "losses") {
        this.commit("clearChart");
        state.lossesChartStatus = true;
      }
    },
    setHeader(state, token) {
      state.header = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      }
    },
    setLoadingStateSmartFeed(state, boolean) {
      state.loadingStateSmartFeed = boolean
    },
    setSmartMicData(state, results) {
      state.smartMicData = []
      state.smartMicData.push(results)
    },
    setSmartFeedData(state, results) {
      state.smartFeedData.push(results)
    },
    setErrorMessage(state, message) {
      state.errorMessage = message
    },
    setNewBarn(state, barn) {
      for (let farm in state.Farms) {
        if (state.Farms[farm].farm === barn.farm) {
          state.Farms[farm].barns.push(barn)
        }
      }
    },
    setNewFarm(state, farm) {
      state.Farms.push({
        farm: farm.pk,
        name: farm.name,
        barns: [],
      })
    },
    updateEditBarn(state, {current_barn, name}) {
      for (let farm in state.Farms) {
        for (let barn in state.Farms[farm].barns) {
          if (state.Farms[farm].barns[barn].pk === current_barn.pk) {
            state.Farms[farm].barns[barn].name = name
          }
        }
      }
    },
    setNewBatch(state, {barn_pk, batch, batch_curve}) {
      for (let farm in state.Farms) {
        const barn = state.Farms[farm].barns.find(barn => barn.pk === barn_pk)
        if (barn) {
          barn.batch = batch
          barn.batch.reference_weight_curve = batch_curve
          barn.batch.batch_losses = barn.batch.batch_losses.length
          state.batch_target = barn.batch
        }
      }
    },
    updateEndBatch(state, {batch, date_departure, final}) {
      let isAnOldBatch = true
      for (let farm in state.Farms) {
        for (let barn in state.Farms[farm].barns) {
          if (state.Farms[farm].barns[barn].batch.id === batch.id) {
            state.Farms[farm].barns[barn].batch.date_departure = date_departure
            state.Farms[farm].barns[barn].batch.average_final_weight = final
            state.Farms[farm].barns[barn].batch.final_count = batch.initial_count - batch.batch_losses
            state.batch_target = state.Farms[farm].barns[barn].batch
            isAnOldBatch = false
          }
        }
      }
      if (isAnOldBatch) {
        for (let farm in state.allBatches) {
          for (let barn in state.allBatches[farm].barns) {
            for (let old_batch in state.allBatches[farm].barns[barn].batches) {
              if (state.allBatches[farm].barns[barn].batches[old_batch].id === batch.id) {
                state.allBatches[farm].barns[barn].batches[old_batch].date_departure = date_departure
                state.allBatches[farm].barns[barn].batches[old_batch].average_final_weight = final
                state.allBatches[farm].barns[barn].batches[old_batch].final_count = batch.initial_count - batch.batch_losses
                state.batch_target = state.allBatches[farm].barns[barn].batches[old_batch]
              }
            }
          }
        }
      }
    },
    updateEditBatch(state, {batch_object, new_date_accommodation, initial, desired, count}) {
      for (let farm in state.Farms) {
        for (let barn in state.Farms[farm].barns) {
          let batch = null
          if (state.Farms[farm].barns[barn].batch?.id === batch_object.id) {
            batch = state.Farms[farm].barns[barn].batch
          }

          if (batch) {
            batch.average_initial_weight = initial
            batch.desired_weight = desired
            batch.initial_count = count
            batch.date_accomodation = new_date_accommodation
          }
        }
      }
    },
    updateEditFarm(state, {current_farm, name}) {
      for (let farm_target in state.Farms) {
        if (state.Farms[farm_target].farm === current_farm.farm) {
          state.Farms[farm_target].name = name
        }
      }
    },
    setBarnTarget(state, barn) {
      state.barn_target = barn
    },
    setBatchTarget(state, batch) {
      state.batch_target = batch
    },
    setAllBatches(state, history) {
      state.allBatches = history
    },
    setIsAdmin(state, role) {
      if (role === "pecsmart_admin" || role === "company_admin" || role === "farm_admin") {
        state.isAdmin = true
      }
      else {
        state.isAdmin = false
      }
    },
    setUserLevel(state, role) {
      if (role === "pecsmart_admin" || role === "pecsmart_employee") {
        state.userLevel = "pecsmart"
      }
      if (role === "company_admin" || role === "company_employee") {
        state.userLevel = "company"
      }
      if (role === "farm_admin" || role === "farm_employee") {
        state.userLevel = "employee"
      }
    },
    setSmartFeedConsolidatedData(state, data) {
      state.smartFeedConsolidatedData = [data]
    },
    setAnimalTypes(state, data) {
      state.animalTypes = data.results
    },
    setCamV3Data(state, data) {
      state.dataCamv3 = data
    }
  },
  actions: {
    async setToken(state, user) {
      const data = {username: `${user.username}`, password: `${user.password}`}
      await fetch(`${URL}auth/login/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
      })
      .then(response => {
        if (response.ok === false) {
          throw new Error
        }
        return response.json()
      })
      .then(json => {
        window.localStorage.setItem("Token", json.key)
        window.localStorage.setItem("username", data.username)
        state.commit("setHeader", window.localStorage.getItem("Token"))
      })
      .catch(() => state.commit("setError", true))
    },
    async registerLoss(state, {batch, weight, reason, death_details, datetime_registered, quantity}) {
      const data = {
        batch: batch,
        weight: weight,
        reason: `${reason}`,
        death_details: `${death_details}`,
        death_datetime: datetime_registered,
        quantity: quantity
      }
      await fetch(`${URL}losses/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: this.getters.getHeader
      })
      .then(response => {
        if (response.ok) {
          state.commit("setError", false)
          state.dispatch('fetchBatchDetail',batch)
        }
        else {
          state.commit("setError", true)
        }
      })
      .catch(() => state.commit("setError", true))
    },
    async registerBatch(state, {barn, animal_type, average_initial_weight, desired_weight, initial_count, date_accomodation}) {

      let farm_target = state.getters.getAllBarns.find(obj =>{
        let barn_exist = obj.barn.find(element=> element.pk == barn)
        if (barn_exist != undefined) return obj
      })

      const reference_curve = await fetch(`${URL}reference_weight_curves/?company=${farm_target.farm.company}`, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())
      .then(json => {
        if (json.results.length !== 0) return {'pk': json.results[0].pk, 'curve': json.results[0].curve}
        return {'pk': null, 'curve': null}
      })

      const data = {
        average_initial_weight: average_initial_weight,
        average_final_weight: null,
        desired_weight: desired_weight,
        initial_count: initial_count,
        final_count: null,
        animal_type: animal_type,
        date_departure: null,
        batch_weights: [],
        reference_weight_curve: reference_curve.pk,
        batch_losses: [],
        batch_accommodation: [],
        barn: barn
      }

      const batch = await fetch(`${URL}batches/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: this.getters.getHeader
      })
      .then(response => response.json())
      .then(json => json)

      const barn_pk = barn
      const batch_pk = batch.pk
      const date = date_accomodation
      const status = await state.dispatch("registerBatchAccomodation", {barn_pk, batch_pk, date})
      
      state.commit('updateBatchTarget', batch)
      state.dispatch('fetchBatchDetail', batch_pk)

      return {'ok': status, 'batch': batch}
    },
    async registerBatchAccomodation(state, {barn_pk, batch_pk, date}) {
      const data = {
        batch: batch_pk,
        barn: barn_pk,
        date_accommodation: date,
      }

      const response = await fetch(`${URL}batch_accommodations/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: this.getters.getHeader
      })
      .then(response => response.ok)
      .catch(() => false)

      return response
    },
    async registerBarn(state, {name, farm_pk}) {
      const data = {
        name: name,
        farm: farm_pk,
        batches: [],
        sensors: [],
        silos: [],
        batch_accommodation: [],
      }

      const response = await fetch(`${URL}barns/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: this.getters.getHeader
      })
      .then(response => response.json())
      .then(json => {
        return {'ok': true, 'data': json}
      })
      .catch(() => {
        return {'ok': false, 'data': null}
      })

      const barn_in_format = {
        "batch": response.data.batches,
        "farm": farm_pk,
        "feed_data": null,
        "mic_data": null,
        "name": response.data.name,
        "pk": response.data.pk,
        "sensors": response.data.sensors,
      }

      if (response.ok) {
        state.commit("setNewBarn", barn_in_format)
      }

      return response.ok
    },
    async editBarn(state, {name, current_barn}) {
      let old_barn = await fetch(`${URL}barns/${current_barn.pk}`, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())

      old_barn.name = name

      let response = await fetch(`${URL}barns/${current_barn.pk}/`, {
        method: "PUT",
        body: JSON.stringify(old_barn),
        headers: this.getters.getHeader
      })
      .then(response => response.ok)
      .catch(() => false)

      if (response) {
        state.commit("updateEditBarn", {current_barn, name})
      }

      return response
    },
    async registerFarm(state, name) {
      const farm = {
        name: name,
        company: state.getters.getCurrentUser.company,
        barns: [],
        employess: []
      }

      const response = await fetch(`${URL}farms/`, {
        method: "POST",
        body: JSON.stringify(farm),
        headers: this.getters.getHeader
      })
      .then(response => response.json())
      .then(json => json)
      .catch(() => false)

      state.commit("setNewFarm", response)
      return true
    },
    async editAccommodation(state, {accommodation_pk, barn_pk, batch_pk, date}) {
      const data = {
        batch: batch_pk,
        barn: barn_pk,
        date_accommodation: date,
      }

      const response = await fetch(`${URL}batch_accommodations/${accommodation_pk}/`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: this.getters.getHeader
      })
      .then(response => response.ok)
      .catch(() => false)

      return response
    },
    async editFarm(state, {name, current_farm}) {
      let old_farm = await fetch(`${URL}farms/${current_farm.farm}`, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())

      old_farm.name = name

      let response = await fetch(`${URL}farms/${current_farm.farm}/`, {
        method: "PUT",
        body: JSON.stringify(old_farm),
        headers: this.getters.getHeader
      })
      .then(response => response.ok)
      .catch(() => false)

      if (response) {
        state.commit("updateEditFarm", {current_farm, name})
      }

      return response
    },
    async endBatch(state, {batch, date_departure, final}) {
      const id = await batch.id == null ? batch.pk : batch.id
      let old_batch = await fetch(`${URL}batches/${id}`, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())

      const updatedBatch = state.getters.getBatchTarget
      updatedBatch.date_departure = date_departure

      old_batch.date_departure = date_departure
      old_batch.average_final_weight = final
      const losses = old_batch.batch_losses.length == null ? old_batch.batch_losses : old_batch.batch_losses.length
      old_batch.final_count = old_batch.initial_count - losses

      let put_response = await fetch(`${URL}batches/${id}/`, {
        method: "PUT",
        body: JSON.stringify(old_batch),
        headers: this.getters.getHeader
      })
      .then(response => response.ok)

      if (put_response) {
        state.commit('updateBatchTarget', updatedBatch)
      }

      return {'ok': put_response, 'batch': batch}
    },
    async editBatch(state, {batch, accommodation, initial, desired, count}) {
      const batch_pk = batch.id == null ? batch.pk : batch.id

      let old_batch = await fetch(`${URL}batches/${batch_pk}`, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())

      old_batch.date_accomodation = accommodation
      old_batch.average_initial_weight = initial
      old_batch.desired_weight = desired
      old_batch.initial_count = count

      let put_response = await fetch(`${URL}batches/${batch_pk}/`, {
        method: "PUT",
        body: JSON.stringify(old_batch),
        headers: this.getters.getHeader
      })
      .then(response => response.ok)

      const barn_pk = state.getters.getBarnTarget.pk
      const accommodation_pk = old_batch.batch_accommodation[0]
      const date = accommodation
     
      if (put_response) {
        state.dispatch('fetchBatchDetail', batch_pk)
        await state.dispatch("editAccommodation", {accommodation_pk, barn_pk, batch_pk, date})
      }

      return {'ok': put_response, 'batch': batch}
    },
    async registerWeight(state, {batch, weight, datetime_registered}) {
      const data = {
        batch: batch.id,
        weight: weight,
        datetime_fetched: datetime_registered
      }
      await fetch(`${URL}batch_manual_weights/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: this.getters.getHeader
      })
      .then(response => {
        if (response.ok) {
          state.commit("setError", false)
        }
        else {
          state.commit("setError", true)
        }
      })
      .catch(() => state.commit("setError", true))
    },
    async setAllBatches(state) {
      const level = state.getters.getUserLevel
      let reportURL = null
      if (level === "pecsmart") {
        reportURL = `${URL}farms/report/history/pecsmart`
      }
      if (level === "company") {
        reportURL = `${URL}farms/report/history/company/${this.getters.getCurrentUser.company}`
      }
      if (level === "employee") {
        const associated_farm = this.getters.getCurrentUser.associated_farm
        reportURL = `${URL}farms/report/history/employee/${this.getters.getCurrentUser.company}/${associated_farm}`
      }
      await fetch(reportURL, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())
      .then(json => {
        state.commit("setAllBatches", json)
      })
    },
    async setCurrentUser(state, username) {
      await fetch(`${URL}users/report/${username}`, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())
      .then(json => {
        window.localStorage.setItem("role", json[0].role)
        state.commit("setCurrentUser", json[0]);
      })
      .catch(() => state.commit("setError", true))
    },
    async setFarm(state) {
      const level = state.getters.getUserLevel
      let reportURL = null
      if (level === "pecsmart") {
        reportURL = `${URL}farms/report/home/pecsmart`
      }
      if (level === "company") {
        reportURL = `${URL}farms/report/home/company/${this.getters.getCurrentUser.company}`
      }
      if (level === "employee") {
        const associated_farm = this.getters.getCurrentUser.associated_farm
        reportURL = `${URL}farms/report/home/employee/${this.getters.getCurrentUser.company}/${associated_farm}`
      }
      await fetch(reportURL, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())
      .then(json => {
        state.commit("setFarm", json)
      })
    },
    async setSelectedFarms(state, value) {
      state.commit("setSelectedFarms", value);
    },
    async login(state, {user, isLoginPage}) {
      try {
        if (isLoginPage) {
          await this.dispatch("setToken", user)
        }
        await this.dispatch("setCurrentUser", user.username)
        const role = state.getters.getCurrentUser.role
        this.commit("setIsAdmin", role)
        this.commit("setUserLevel", role)
        state.commit("setNavbar", true)
        if (router.history.current.name !== "HomePage") {
          router.push('home-page')
        }
      }
      catch(err) {
        this.commit("setError", true)
      }
    },
    async startHome(state) {
      try {
        state.commit("setLogStatus", true)
        await this.dispatch("fetchFarms", this.getters.getCurrentUser.company)
      }
      catch(err) {
        this.commit("setError", true)
      }
    },
    async cleanStore(state) {
      state.commit("setDefaultValues")
    },
    async chartDisplay (state, chart) {
      state.commit("chartDisplay", chart)
    },
    async logout () {
      await fetch(`${URL}auth/logout/`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
    },
    async fetchSmartMicData(state, batch_pk) {
      await fetch(`${URL}coughs/batch_report/${batch_pk}/`, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())
      .then(json => {
        json.batch = batch_pk
        state.commit("setSmartMicData", json)
      })
      .catch(err => console.log(err))
    },
    async fetchFeedData(state, {barn, accommodation, departure, silo}) {
      if (state.getters.getSmartFeedData.find(item => item.silo === silo) != undefined) return;
      fetch(`${URL}silo_feed_weights/report/${barn}/${silo}/${accommodation}/${departure}`, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())
      .then(json => {
        state.commit("setSmartFeedData", json)
      })
      .catch(() => {
        throw "error"
      })
    },
    async fetchFeedConsolidatedData(state, {barn, accommodation, departure}) {
      try {
        const response = await fetch(`${URL}silo_feed_weights/report/consume/${barn}/${accommodation}/${departure}/`, {
          headers: this.getters.getHeader,
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.details || "An error occurred");
        }
    
        const json = await response.json();
    
        const data = json.data.sort((a, b) => new Date(a.date) - new Date(b.date));
        json.data = data;
        state.commit("setSmartFeedConsolidatedData", json);
      } catch (error) {
        if (error.message.includes('Unexpected token')) {
          state.commit('setErrorMessage', "Error no servidor")
        } else {
          state.commit('setErrorMessage',error.message)
        }
        throw error;
      }
    },
    async fetchAnimalTypes(state, company) {
      if (this.getters.getAnimalTypes != null) return;
      let url_to_fetch = this.getters.getUserLevel === 'pecsmart' ? `${URL}animal_type/` : `${URL}animal_type/?sex=&company=${company}`
      await fetch(url_to_fetch, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())
      .then(json => {
        state.commit("setAnimalTypes", json)
      })
      .catch(err => console.log(err))
    },
    async fecthLossesByBatch(state, {batch, url}) {
      let url_to_fetch = url == null ? `${URL}losses/?batch=${batch}` :  url
       await fetch(url_to_fetch, {
         headers: this.getters.getHeader
       })
       .then(response => response.json())
       .then(json => {
        
        if (json.next !== null) {
          this.dispatch('fecthLossesByBatch', {batch: batch, url: json.next})
        }
        state.commit("setLossesBatch", json)
       })
       .catch(err => console.log(err))
     },
     async fetchFarms(state, company) {
      let url_to_fetch = `${URL}farms/report/home/company=${company}`
      await fetch(url_to_fetch, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())
      .then(json => {
        json.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        state.commit("setFarm", json)

      })
      .catch(err => console.log(err))
    },
    async fetchCamData(state, batch) {
      let url_to_fetch = `${URL}batch_weights/batch_weight_report/${batch}`
      await fetch(url_to_fetch, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())
      .then(json => {
        this.commit('setCamData', json.reverse())
      })
      .catch(err => console.log(err))
    },
    async fetchBatchDetail(state, batch) {
      let url_to_fetch = `${URL}batches/report/home/batch=${batch}`
      await fetch(url_to_fetch, {
        headers: this.getters.getHeader,
        cache: 'reload'
      })
      .then(response => response.json())
      .then(json => {
        this.commit('setBatchDetail', json)
      })
      .catch(err => console.log(err))
    },
    async fetchReferenceWeightCurve(state, animalType) {
      let url_to_fetch = `${URL}animal_type/${animalType}`
      await fetch(url_to_fetch, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())
      .then(json => {
        this.commit('setCurveWeightReference', json.weight_gain_reference_curve)
      })
      .catch(err => console.log(err))
    },
    async fetchCompanySettings(state, company) {
      let url_to_fetch = `${URL}company_settings/?company=${company}`
      await fetch(url_to_fetch, {
        headers: this.getters.getHeader
      })
      .then(response => response.json())
      .then(json => {
        this.commit('setCompanySettings', json.results[0])
      })
      .catch(err => console.log(err))
    },
    async fetchCompanies() {
      let url_to_fetch = `${URL}companies/?ordering=name`;
      await fetch(url_to_fetch, {
        headers: this.getters.getHeader,
      })
        .then((response) => response.json())
        .then((json) => {
          this.commit("setCompanies", json.results);
        })
        .catch((err) => console.log(err));
    },
    async fetchCamV3(state, batch) {
      console.log('camv3',batch)
      let url_to_fetch = `${URL_CAMV3}get_predictions_for_batch/${batch}`;
      await fetch(url_to_fetch, {
        headers: this.getters.getHeader,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status) {
            this.commit("setCamV3Data", json.status);
          } else {
            this.commit("setCamV3Data", json);
          }
          
        })
        .catch((err) => console.log(err));
    },

  },
  modules: {},
  getters: {
    getCurrentUser: state => state.currentUser,
    getAllFarms: state => state.Farms,
    getAllBatches: state => state.allBatches,
    getSelectedFarms: state => state.selectedFarms,
    getLogedIn: state => state.logedIn,
    getError: state => state.error,
    getKillWeight: state => state.killWeight,
    getManualWeights: state => state.manualWeights,
    getWeightChartStatus: state => state.weightChartStatus,
    getStandardDeviationChartStatus: state => state.standardDeviationChartStatus,
    getUniformityChartStatus: state => state.uniformityChartStatus,
    getGpdWeekChartStatus: state => state.gpdWeekChartStatus,
    getGpdAccChartStatus: state => state.gpdAccChartStatus,
    getHeader: state => state.header,
    getLoadingStateSmartFeed: state => state.loadingStateSmartFeed,
    getSmartMicData: state => state.smartMicData,
    getSmartFeedData: state => state.smartFeedData, 
    getSmartCamData : state => state.smartCamData,
    getSensorsListFeed : state => state.sensorsListFeed,
    getSensorsListMic : state => state.sensorsListMic,
    getSensorsListCam : state => state.sensorsListCam,
    getBatchDetail : state => state.batchDetail,
    getCurveWeightReference: state=> state.curveWeightReference,
    getActiveRequest: state => state.activeRequests,
    getAllBarns: state => state.allBarns,
    getStateBarns: state => state.stateBarns,
    getHistoryBatch: state => state.historyBatch,
    getCompanySettings: state => state.companySettings, 
    getLossesBatch: state => state.lossesBatch,
    getLossesChartStatus: state => state.lossesChartStatus,
    getCamv3Data: state => state.dataCamv3,
    getCompanies: state => state.companies,
    getNavbar: state => state.navbar,
    getBarnTarget: state => state.barn_target,
    getBatchTarget: state => state.batch_target,
    getIsAdmin: state => state.isAdmin,
    getUserLevel: state => state.userLevel,
    getSmartFeedConsolidatedData: state => state.smartFeedConsolidatedData,
    getAnimalTypes: state => state.animalTypes,
    getErrorMessage: state => state.errorMessage
  }
})